import {
  checkoutDataToQuery,
  planCustomizationDataToQuery,
  encodeBase64Url,
  integrationDataToQuery,
  thankYouDataToQuery,
  integrationDataToAppSectionParams,
  TpaPageId,
} from '@wix/pricing-plans-router-utils';
import type { SubPage } from '../types/common';

export const PageSectionIdMap: Record<SubPage['name'], TpaPageId> = {
  checkout: TpaPageId.Checkout,
  customization: TpaPageId.PlanCustomization,
  status: TpaPageId.ThankYou,
  restricted: TpaPageId.Paywall,
  list: TpaPageId.PackagePicker,
};

export const getSectionId = (
  subPageName: SubPage['name'],
  { isMultiPageApp }: { isMultiPageApp: boolean },
): TpaPageId => (isMultiPageApp ? PageSectionIdMap[subPageName] : PageSectionIdMap.list);

export const getSubPagePath = (subPage: SubPage, { isMultiPageApp }: { isMultiPageApp: boolean }) =>
  isMultiPageApp ? subPageToPathMPA(subPage) : subPageToPathSPA(subPage);

function subPageToPathMPA(subPage: SubPage): string {
  const addQueryStringPrefix = (path: string): `?${string}` | '' => (path !== '' ? `?${path}` : '');
  switch (subPage.name) {
    case 'checkout':
      return addQueryStringPrefix(checkoutDataToQuery(subPage.checkoutData).toString());
    case 'customization':
      return addQueryStringPrefix(planCustomizationDataToQuery(subPage.planCustomizationData).toString());
    case 'status':
      return addQueryStringPrefix(thankYouDataToQuery(subPage.statusData).toString());
    case 'restricted':
      return '/';
    default:
    case 'list':
      return addQueryStringPrefix(integrationDataToQuery(subPage.integrationData).toString());
  }
}

function subPageToPathSPA(subPage: SubPage): string {
  switch (subPage.name) {
    case 'checkout':
      return '/payment/' + encodeBase64Url(subPage.checkoutData);
    case 'customization':
      return ''; // Should not happen
    case 'status':
      return `/status/${encodeBase64Url(subPage.statusData)}`;
    case 'restricted':
      return '/restricted';
    default:
    case 'list':
      return '?' + integrationDataToAppSectionParams(subPage.integrationData).toString();
  }
}
