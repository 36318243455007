import { loaderAnimation } from './loader-animation';

export function displayLoader(button: $w.Button, loaderAnimationTime: number) {
  const originalLabel = button.label;

  const resetButton = () => {
    button.enable();
    button.label = originalLabel;
    button.icon = '<svg></svg>';
  };

  try {
    button.disable();
    button.icon = loaderAnimation[0];
    button.label = '';
  } catch (error) {
    resetButton();
    return null;
  }

  const frameDelay = 50;
  const startTime = performance.now();
  let lastFrameTime = startTime;
  let frameIndex = 0;
  let rafId: number;

  const animate = (currentTime: number) => {
    try {
      const elapsedSinceLastFrame = currentTime - lastFrameTime;
      const totalElapsed = currentTime - startTime;

      if (totalElapsed >= loaderAnimationTime) {
        resetButton();
        return;
      }

      if (elapsedSinceLastFrame >= frameDelay) {
        frameIndex = (frameIndex + 1) % loaderAnimation.length;
        button.icon = loaderAnimation[frameIndex];
        lastFrameTime = currentTime;
      }

      rafId = requestAnimationFrame(animate);
    } catch (error) {
      cancelAnimationFrame(rafId);
      resetButton();
    }
  };

  rafId = requestAnimationFrame(animate);

  return () => {
    if (rafId) {
      cancelAnimationFrame(rafId);
      resetButton();
    }
  };
}
