export const loaderAnimation = [
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="10" cy="10" r="3" fill-opacity="0.6"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="10" cy="10" r="5" fill-opacity="0.8"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="10" cy="10" r="8" fill-opacity="0.9"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="10" cy="10" r="10" />
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="10" cy="10" r="10" />
        <circle cx="40" cy="10" r="3" fill-opacity="0.6"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="10" cy="10" r="10" />
        <circle cx="40" cy="10" r="5" fill-opacity="0.8"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="10" cy="10" r="8" fill-opacity="0.9"/>
        <circle cx="40" cy="10" r="8" fill-opacity="0.9"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="10" cy="10" r="5" fill-opacity="0.8"/>
        <circle cx="40" cy="10" r="10" />
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="10" cy="10" r="3" fill-opacity="0.6"/>
        <circle cx="40" cy="10" r="10" />
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="40" cy="10" r="10" />
        <circle cx="70" cy="10" r="3" fill-opacity="0.6"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="40" cy="10" r="10" />
        <circle cx="70" cy="10" r="5" fill-opacity="0.8"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="40" cy="10" r="8" fill-opacity="0.9"/>
        <circle cx="70" cy="10" r="8" fill-opacity="0.9"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="40" cy="10" r="5" fill-opacity="0.8"/>
        <circle cx="70" cy="10" r="10" />
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="40" cy="10" r="3" fill-opacity="0.6"/>
        <circle cx="70" cy="10" r="10" />
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="70" cy="10" r="8" fill-opacity="0.9"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="70" cy="10" r="5" fill-opacity="0.8"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <circle cx="70" cy="10" r="3" fill-opacity="0.6"/>
    </svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg"></svg>`,
  `<svg width="80" height="20" viewBox="0 0 80 20" xmlns="http://www.w3.org/2000/svg"></svg>`,
];
