import { createCheckout, getCheckout } from '@wix/ambassador-ecom-v1-checkout/http';
import { ChannelType, Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { PlanOptions } from '@wix/ambassador-pricing-plans-v3-plan/types';
import type { IHttpClient, IWixAPI } from '@wix/yoshi-flow-editor';
import { PRICING_PLANS_APP_DEF_ID } from '../../../constants';

type WixCodeApi = IWixAPI;

export class EcomService {
  private readonly ECOM_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';
  constructor(private readonly httpClient: IHttpClient, private readonly wixCodeApi: WixCodeApi) {}

  async navigateToCheckout(checkoutId: string, successUrl?: string) {
    const ecomPublicApi = await this.wixCodeApi.site.getPublicAPI(this.ECOM_APP_DEF_ID);
    await ecomPublicApi.navigate.toCheckout({
      checkoutId,
      disableContinueShopping: true,
      successUrl,
    });
  }

  async createCheckout(planId: string, planOptions: PlanOptions): Promise<Checkout> {
    const result = await this.httpClient.request(
      createCheckout({
        channelType: ChannelType.WEB,
        lineItems: [
          {
            quantity: 1,
            catalogReference: {
              appId: PRICING_PLANS_APP_DEF_ID,
              catalogItemId: planId,
              options: {
                type: 'PLAN',
                planOptions,
              },
            },
          },
        ],
      }),
    );
    return result.data.checkout!;
  }

  async getCheckout(checkoutId: string): Promise<Checkout> {
    const result = await this.httpClient.request(getCheckout({ id: checkoutId }));
    return result.data.checkout!;
  }
}
