import { IHttpClient } from '@wix/yoshi-flow-editor';

type InPopulationResponse = {
  inPopulation?: boolean;
};

type ProvisionResponse = {
  success: boolean;
};

export class EcomServerless {
  constructor(private httpClient: IHttpClient) {}

  public async isInPopulation() {
    try {
      const response = await this.httpClient.get<InPopulationResponse>(`/_api/pricing-plans-ecom/in-population`);
      const { inPopulation } = response.data;
      return Boolean(inPopulation);
    } catch (e) {
      // Just swallow exception, but not sure its great...
      return false;
    }
  }

  public async provision() {
    try {
      const response = await this.httpClient.post<ProvisionResponse>(`/_api/pricing-plans-ecom/provision`);
      const { success } = response.data;
      return success;
    } catch (e) {
      // Just swallow exception, but not sure its great...
      return false;
    }
  }
}
